body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

#root {
  display: flex;
  flex-direction: column;
}

div.lhsidebar {
  margin: 0;
  padding: 0;
}

div.lhsidebar .modal-content {
  border: none;
  border-radius: 0;
  height: 100vh;
  width: 250px;
  overflow: auto;
}

div.lhsidebar * li {
  list-style-type: none;
}

div.lhsidebar * a {
  text-decoration: none;
  color: black;
}

.lhsidebar-link:hover {
  background-color: darkgray;
  cursor: pointer;
}

div.modalFiltros {
  margin: 0;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

div.modalFiltros .modal-content {
  border: none;
  border-radius: 0;
  height: 100vh;
  width: 350px;
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  #root {
    overflow: auto;
  }

  #lhDivPrincipal {
    overflow: auto;
  }

  div.modalFiltros .modal-content {
    border: none;
    border-radius: 0;
    height: 100vh;
    width: 85%;
    overflow: auto;
  }

  .onlyprint {
    display: none;
  }
}

@media print {
  .onlyprint {
    display: block;
  }

  .noprint {
    display: none;
  }

  .mt-print-0 {
    margin-top: 0px !important;
  }
}

.h-10 {
  height: 10% !important;
}
.h-15 {
  height: 15% !important;
}
.h-20 {
  height: 20% !important;
}
.h-25 {
  height: 25% !important;
}
.h-30 {
  height: 30% !important;
}
.h-35 {
  height: 35% !important;
}
.h-40 {
  height: 40% !important;
}
.h-45 {
  height: 45% !important;
}
.h-50 {
  height: 50% !important;
}
.h-55 {
  height: 55% !important;
}
.h-60 {
  height: 60% !important;
}
.h-65 {
  height: 65% !important;
}
.h-70 {
  height: 70% !important;
}
.h-75 {
  height: 75% !important;
}
.h-80 {
  height: 80% !important;
}
.h-85 {
  height: 85% !important;
}
.h-90 {
  height: 90% !important;
}
.h-95 {
  height: 95% !important;
}

.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
