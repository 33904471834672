
div.lhsidebar {
    margin: 0;
    padding:0;
}
  
div.lhsidebar .modal-content {
    border: none;
    border-radius: 0;
    height: 100vh;
    width: 250px;
    overflow: auto;
}
  
div.lhsidebar * li {
  list-style-type: none;
}
  
div.lhsidebar * a {
  text-decoration: none;
  color: black;
}
  
.lhsidebar-link:hover {
  background-color: darkgray;
  cursor: pointer;
}
  
div.modalFiltros {
  margin: 0;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

div.modalFiltros .modal-content {
  border: none;
  border-radius: 0;
  height: 100vh;
  width: 350px;
  overflow: auto;
}

@media only screen and (max-width: 600px){
  div.modalFiltros .modal-content {
      border: none;
      border-radius: 0;
      height: 100vh;
      width: 85%;
      overflow: auto;
  }   
}